<template>
  <div>
      <vue-header title="活动报名" :notoken="true" />
      <div v-if="!diaoyan">
        <div class="bac"></div>
        <img src="/banner.png" class="banner">
        <div class="sub-box" >
            <div class="sub-intro">
                    <h1>基层卫生专病数智物理治疗的临床数据分析与研究</h1>
                    <p>本次发起“数智物理治疗的临床数据分析与研究”活动，是教授之家智慧中医数研院近年在转化医学方面的重要成果之一，遴选出头痛病、颈椎病、乳腺病、痛风与失眠五大专项疾病的数智物理治疗临床应用与疗效评价分析，对提升基层医疗全科医生和西学中医生的临床专病诊疗水平，丰富诊疗手段具，加强医疗服务治疗具有重要意义。</p>
                    <h1>研究机构介绍：教授之家智慧中医数研院</h1>
                    <p>教授之家智慧中医数研院是基于公共卫生、全科医学、中医学、人工智能软件研发、医疗卫生数据分析与处理为一体的产学研用高度融合的新型机构，为浙江省医疗机构数字化改革提供技术支撑、人才培养，是推动产、学、研、用融合基地。数研院立足医疗数字化改革的基础和经验，为医疗机构提供科研平台：临床专病诊疗研究；数据整合、数据对比、数据处理与服务、数据挖掘与信息共享；医疗信息化发展战略研究；科研期刊文献整理与发布。</p>
                    <h2>活动报名：</h2>
            </div>
            <div class="sub-input">
                <div v-for="( prob , index ) in problem" :key="index">
                    <van-cell-group inset v-if="prob.type=='text'||prob.type=='tel'">
                        <van-field :type="prob.type" v-model="answer[prob.state]" :label="`${prob.name}：`" :placeholder="prob.placeholder" />
                    </van-cell-group>
                    <div class="radio-box flex" v-if="prob.type=='radio'">
                        <div class="radio-title">{{prob.name}}：</div>
                        <div class="flex-1">
                            <van-radio-group v-model="answer[prob.state]" direction="horizontal">
                                <van-radio :name="child.name" v-for="( child , index1 ) in prob.children" :key="index1">{{child.name}}</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div class="checkbox-box" v-if="prob.type=='checkbox'">
                        <div class="checkbox-title">{{prob.name}}：</div>
                        <van-checkbox-group v-model="answer[prob.state]">
                            <van-checkbox shape="square" :name="child.name" v-for="( child , index1 ) in prob.children" :key="index1">{{child.name}}</van-checkbox>
                        </van-checkbox-group>
                    </div>
                </div>
            </div>
            <div class="sub-intro">
                    <h2>参与科研要求：</h2>
                    <p>本次科研需以机构形式参与合作，若个人有相关意向，请与所在机构申请后报名参与；</p>
                    <h2>报名交费：</h2>
                    <p>本次科研项目包含以下费用：研究工具费，病例观察费，数据收集费，数据分析费，文章出版费共50000（伍万）元</p>
                    <div>转账方式：电子汇款及转账<br/>汇款账号：<br/>开户银行：<br/>备注信息：机构名称+转化医学科研项目</div>
            </div>
            <div class="sub-submit flex flex-x">
                <div class="submit" @click="signupFun">提交报名</div>
            </div>
        </div>
      </div>
      <div class="flex flex-x flex-y" v-else>
          <div class="passed">
              <van-icon name="passed" />
              <div class="sub-success">报名成功</div>
          </div>
      </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapActions , mapState } from 'vuex';
import { Toast } from 'vant';
import { titleFun } from '@/utils';
export default {
  name: 'record',
  components:{
    vueHeader
  },
  data(){ 
    return {
        problem:[{
            name:'机构名称',
            state:'company_name',
            must:true,
            type:'text',
            placeholder:'请输入机构名称'
        },{
            name:'姓名',
            state:'name',
            must:true,
            type:'text',
            placeholder:'请输入您的姓名'
        },{
            name:'电话',
            state:'phone',
            must:true,
            type:'tel',
            placeholder:'请输入您的联系方式'
        },{
            name:'邮箱',
            state:'email',
            type:'text',
            placeholder:'请输入您的邮箱'
        },{
            name:'科室',
            state:'keshi',
            type:'text',
            placeholder:'请输入您所在的科室'
        },{
            name:'职务',
            state:'zhiwu',
            type:'text',
            placeholder:'请输入您的职务'
        },{
            name:'职称',
            state:'zhicheng',
            type:'radio',
            show:'show',
            children:[{
                name:'初级'
            },{
                name:'中级'
            },{
                name:'副高'
            },{
                name:'正高'
            }]
        },{
            name:'执业范围',
            state:'zhiye',
            type:'text',
            placeholder:'请输入您的执业范围'
        },{
            name:'选择科研方向',
            state:'keyang',
            type:'checkbox',
            children:[{
                name:'头痛病专项数智物理治疗的临床数据分析与研究'
            },{
                name:'颈椎病专项数智物理治疗的临床数据分析与研究'
            },{
                name:'乳腺病专项数智物理治疗的临床数据分析与研究'
            },{
                name:'痛风专项数智物理治疗的临床数据分析与研究'
            },{
                name:'失眠专项数智物理治疗的临床数据分析与研究'
            }]
        }],
        answer:{
            keyang:[]
        }
    }
  },
  created:function(){
      titleFun('活动报名')
  },
  computed:{
      ...mapState('my',['diaoyan']),
  },
  methods:{
      signupFun(){
          let bool = true;
          this.problem.map((prob)=>{
              if(bool && prob.must){
                  if(!this.answer[prob.state]){
                      Toast.fail(prob.placeholder);
                      bool = false;
                  }
              }
          });
          if(bool){
              this.diaoyanMain(this.answer);
          }
      },
      ...mapActions('my',['diaoyanMain'])
  },
}
</script>

<style lang="scss" scoped>
  @import './questionnaire';
</style>
